$spacings: (
    '2xs': .5rem,
	'xs': 1rem,
	'sm': 2rem,
	'md': 3rem,
    'lg': 4rem,
    'xl': 5rem,
    '2xl': 7rem
);

:root {
    --spacing--2xs: #{map-get($spacings, '2xs')};
    --spacing--xs: #{map-get($spacings, 'xs')};
    --spacing--sm: #{map-get($spacings, 'sm')};
    --spacing--md: #{map-get($spacings, 'md')};
    --spacing--lg: #{map-get($spacings, 'lg')};
    --spacing--xl: #{map-get($spacings, 'xl')};
    --spacing--2xl: #{map-get($spacings, '2xl')};
}

@each $name, $value in $spacings {  
    .margin-bottom--#{$name} {
        margin-bottom: #{$value};
    }

    .margin-top--#{$name} {
        margin-top: #{$value};
    }

    .margin-right--#{$name} {
        margin-right: #{$value};
    }
}