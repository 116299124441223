@mixin grid__cell--width($width) {
	flex: 0 0 $width;
	max-width: $width;
}

@mixin grid__cells {
	@for $col from 1 through 12 {

		$fraction: $col / 12;

		&--width-#{$col} {
			@include grid__cell--width($fraction * 100%);
		}
	}
}

@mixin grid__responsive-cells($screen-width) {
	@for $col from 1 through 12 {

		$fraction: $col / 12;

		&--width-#{$col}\@#{$screen-width} {
			flex: 0 0 $fraction * 100%;
			max-width: $fraction * 100%;
			width: $fraction * 100%;
		}
	}
}

:root {
    --grid-gutter: 3rem;
}

.grid {
    display: flex;
    margin-right: calc(var(--grid-gutter) * -1);
    margin-left: calc(var(--grid-gutter) * -1);
}

.grid--gutter-sm {
	margin-right: calc(var(--grid-gutter) * .3 * -1);
	margin-left: calc(var(--grid-gutter) * .3 * -1);
	
	> .grid__cell {
		padding-left: calc(var(--grid-gutter) * .3);
   		padding-right: calc(var(--grid-gutter) * .3);
	}
}

.grid--wrap {
	flex-wrap: wrap;
}

.grid--column {
    flex-direction: column;
}

.grid--no-gutter {
    margin-left: 0;
    margin-right: 0;

    > .grid__cell {
        padding-left: 0;
        padding-right: 0;
    }
}

.grid--row-gap {
	margin-bottom: calc((var(--grid-gutter) / 2) * -1);

	> .grid__cell {
		padding-bottom: calc(var(--grid-gutter) / 2);
	}
}

.grid--align-center {
    align-items: center;
}

.grid--space-between {
	justify-content: space-between;
}

.grid--justify-center {
	justify-content: center;
}

.grid--align-right {
    justify-content: flex-end;
}

.grid__cell {
    flex: 1;
    padding-left: var(--grid-gutter);
    padding-right: var(--grid-gutter);
    &--flex  {
		display: flex;
	}
    @include grid__cells();

    @include mq('sm') {
		@include grid__responsive-cells(sm);
	}

	@include mq('md') {
		@include grid__responsive-cells(md);
	}
}

.grid__cell--align-right {
    align-self: flex-end;
}

.grid__cell--no-grow {
	flex-grow: 0;
}

.container {
    max-width: 90rem;
    margin-right: auto;
    margin-left: auto;
    padding: 0 2rem;
}
