.counter-badge__wrapper {
    background-color: var(--color-counter-small-bg);
    border-radius: 1rem;
    display: inline-flex;
    align-items: center;
    padding: .2rem .8rem;
}

.counter-badge__icon {
    width: 1rem;
    margin-right: .5rem;
    transition: transform .1s ease;

    .counter-badge--negative & {
        transform: rotate(180deg);
    }
}

.counter-badge__value {
    font-size: 1.4rem;
    line-height: 130%;
    font-weight: 500;
    color: var(--color-counter-small);
}