.button {
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    padding: 1.4rem 4.5rem;
    font-size: 1.4rem;
    line-height: 135%;
    border-radius: 4rem;
    color: var(--color-white);
    display: flex;
    font-size: 2rem;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 3rem rgba(black, .2);
    transition: box-shadow .1s ease, transform .1s ease;
    text-decoration: none;

    &--round,
    &--secondary {
        font-size: 1.4rem;
        font-weight: 500;
    }

    &--small {
        padding: .8rem 3rem;
    }
    
    &--round {
        width: 5.4rem;
        height: 5.4rem;
        padding: 0;
    }

    &--primary {
        background-color: var(--color-button-bg--primary);
    }

    &--secondary {
        background-color: var(--color-button-bg--secondary);
        text-transform: uppercase;
    }

    &--tertiary {
        background-color: var(--color-button-bg--tertiary);
    }
    
    &:focus {
        outline: 0;
    }
    
    &:active {
        transform: translateY(.1rem);
        box-shadow: 0 0 .1rem rgba(black, .2);
    }
}

.button__icon {
    width: 2.2rem;
    height: 2.2rem;
    pointer-events: none;
}