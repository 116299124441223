@import '@styles/_breakpoint.scss';

.input-label {
    display: flex; 
    align-items: center;
}

.input-label__label {
    color: var(--color-white);

    @media print {
        color: var(--color-black);
    }
}

.input-label__text {
    display: flex;
    font-size: 2rem;
    line-height: 135%;
    font-weight: 300;
    align-items: center;
}

.input-label__description {
    display: block;
    font-size: 1.4rem;
    line-height: 135%;
    font-weight: 400;
    color: var(--color-offwhite);

    @media print {
        color: var(--color-black);
    }
}

.input-label__icon {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    margin-left: 1rem;
    cursor: pointer;
}

.input-label__icon-asset {
    width: 100%;
    opacity: .5;
}

.input-label__tooltip {
    padding: var(--spacing--sm) !important;
    color: var(--color-dove-gray) !important;
    box-shadow: 0 0 .1rem rgba(black, .2) !important;
    line-height: 135% !important;
    font-size: 1.6rem !important;
    opacity: 1 !important;
    max-width: 60vw !important;

    @include mq('sm') {
        max-width: 40rem !important;
    }
}