@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    opacity: 0;
    animation: fade-in forwards .2s ease;
}