@import '@styles/_breakpoint.scss';
@import '@styles/_print.scss';

.card {
    background-color: var(--color-white);
    border-radius: 2rem;
    padding: var(--spacing--lg) var(--spacing--md) var(--spacing--2xl) var(--spacing--md);
    color: var(--color-dove-gray);
    transition: border-radius .3s ease;

    @include mq('sm') {
        padding: var(--spacing--lg) var(--spacing--xl) var(--spacing--2xl) var(--spacing--xl);
    }

    &.is-open {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    &--align-top {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    &--gray {
        background-color: var(--color-gallery);

        @media print {
            background-color: var(--color-white);
        }
    }
}

.card__content {
    opacity: .5;

    .is-active & {
        opacity: 1;
    }
}