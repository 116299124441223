.badge {
    display: inline-block;
    background-color: var(--color-badge-bg);
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: .1rem;
    font-weight: 900;
    color: var(--color-white);
    line-height: 125%;
    padding: .4rem 1.4rem;
    border-radius: 1.2rem;
}