.counter__icon {
    width: 1rem;
    margin-right: .5rem;
    display: none;
}

.counter__value {
    font-size: 6rem;
    line-height: 80%;
    letter-spacing: -.02rem;
    font-weight: 300;
    display: block;
}

.counter__label {
    font-size: 1.6rem;
    line-height: 135%;
    display: block;
}