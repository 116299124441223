.input-field {
    width: 100%;
    position: relative;

    &--text:after {
        content: "";
        display: block;
        position: absolute;
        border-bottom: 1px solid var(--color-white);
        bottom: -.4rem;
        width: 100%;

        @media print {
            border-bottom-color: var(--color-black);
        }
    }
}

.input-field__input {
    background-color: transparent;
    border: 0;
    padding: 0;
    border-bottom: .1rem solid var(--color-offwhite);
    font-size: 3.6rem;
    line-height: 135%;
    color: var(--color-white);
    font-weight: 300;
    text-align: right;
    transition: border-color .1s ease;
    padding-left: 4rem;
    width: 100%;
    display: block;
    height: 4.5rem;
    cursor: text;

    @media print {
        color: var(--color-black);
        border-bottom-color: var(--color-black);
    }

    &::placeholder {
        color: var(--color-offwhite);
        opacity: 1; /* Firefox */

        @media print {
            color: var(--color-black);
        }
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: var(--color-offwhite);

        @media print {
            color: var(--color-black);
        }
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
        color: var(--color-offwhite);

        @media print {
            color: var(--color-black);
        }
    }
    
    &:valid,
    .input-field--text & {
        border-bottom-color: var(--color-white);

        @media print {
            border-bottom-color: var(--color-black);
        }
    }

    &:focus,
    &:active {
        outline: 0;  
    }
}

.input-field__prefix {
    color: var(--color-offwhite);
    position: absolute;
    left: 0;
    bottom: 0;
    padding-bottom: .7rem;
    font-size: 1.6rem;
    line-height: 135%;
    font-weight: 500;
    transition: color .1s ease;

    @media print {
        color: var(--color-black);
    }

    .input-field__input:valid + &,
    .input-field--text & {
        color: var(--color-white);

        @media print {
            color: var(--color-black);
        }
    }
}