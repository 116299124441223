html {
	font-size: 62.5%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-family: 'Yantramanav', sans-serif;
	font-size: 1.6rem;
	background-color: var(--color-green-pea);

	@media print {
		background-color: var(--color-white);
	}
}

* {
	&,
	&:before,
	&:after {
		box-sizing: border-box;
	}
}

h1, h2, h3, h4, h5, h6, p, span {
	margin: 0;
	padding: 0;
}

input {
	border-radius: 0;
	-webkit-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
}

input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }