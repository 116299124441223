.link {
    cursor: pointer;
    padding: 0;
    border: 0;
    background-color: transparent;
    color: var(--color-white);
    font-size: 2rem;
    line-height: 130%;
    font-weight: 300;
    text-decoration: none;
    display: flex;
    align-items: center;
    position: relative;
}

.link__text {
    position: relative;

    &:after {
        content:"";
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        bottom: .1rem;
        border-bottom: .1rem solid var(--color-white);
        opacity: .7;
    }
}