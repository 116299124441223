.calculations {
    padding: 0 5rem;
}

.calculations table {
    color: var(--color-white);
    border-collapse: collapse;
    margin-bottom: 3rem;

    th {
        font-weight: 400;
        font-size: 1.6rem;
    }

    th, td {
        padding: 1rem;
        border: .1rem solid var(--color-white);
    }
}

.calculations h1 {
    color: var(--color-white);
    margin-bottom: 1rem;
}