.modal {
	background-color: var(--color-white);
	border-radius: 2rem;
	width: 60%;
	margin: 0 auto;
	padding: var(--spacing--2xl);
	color: var(--color-dove-gray);
	position: relative;
	border: 1px solid var(--color-puerto-rico);

	&__header {
		color: var(--color-bottle-green);
		width: 100%;
		padding: var(--spacing--sm);
	}

	&__content {
		border: 1px solid var(--color-puerto-rico);
		border-radius: 2rem;
		width: 100%;
		height: 300px;
		overflow: auto;
		padding: var(--spacing--sm);
	}

	&__close {
		cursor: pointer;
		position: absolute;
		display: block;
		padding: 2px 5px;
		line-height: 20px;
		right: -5px;
		top: -5px;
		font-size: 24px;
		border-radius: 2rem;
		background-color: var(--color-puerto-rico);
		color: var(--color-white);
		border: 1px solid var(--color-green-spring);
	}

}
