@import '@styles/_breakpoint.scss';

.chart-label {
    display: flex;
    align-items: center;

    @include mq('sm') {
        justify-content: flex-start;
    }
}

.chart-label__color {
    width: 2.5rem;
    flex: 0 0 2.5rem;
    height: .9rem;
    border-radius: .5rem;
    display: inline-block;
    margin-right: 1rem;
    margin-bottoM: .1rem;
    // Hack for print
    box-shadow: inset 0 0 0 100rem transparent;
}

.chart-label__text {
    font-size: 1.6rem;
    line-height: 135%;
}