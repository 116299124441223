:root {
    --color-spring-leaves: #60876B;
    --color-juniper: #6A8C92;
    --color-cascade: #93A9A8;
    --color-green-pea: #14493A;
    --color-green-spring: #BFC7BA;
    --color-puerto-rico: #37BEA1;
    --color-dove-gray: #666666;
    --color-silver: #CCCCCC;
    --color-gallery: #EFEFEF;
    --color-silver-rust: #C8BEB6;
    --color-international-orange: #FF5200;
    --color-bottle-green: #0F4032;
    --color-green-pea: #175644;
    --color-aqua-forrest: #69AA7B;
    --color-fringy-flower: #B2DEBE;
    --color-eastern-blue: #2299AD;
    --color-morning-glory: #A7D8E1;
    --color-almond-forrest: #917E6D;
    --color-fire-bush: #E49126;
    --color-golden-sand: #EEDB73;
    --color-cinnabar: #E43825;
    --color-mandys-pink: #F6C3BD;
    --color-lochmara: #007BE1;
    --color-mineral-green: #3F665B;

    --color-offwhite: #{rgba(#FFFFFF, .3)};
    --color-white: #FFFFFF;
    --color-gray: #EFEFEF;

    // Badge
    --color-badge-bg: var(--color-international-orange);

    // Button
    --color-button-bg--primary: var(--color-puerto-rico);
    --color-button-bg--secondary: var(--color-mineral-green);
    --color-button-bg--tertiary: var(--color-green-pea);

    // Counter
    --color-counter-small-bg: var(--color-gallery);
    --color-counter-small: var(--color-dove-gray);

    // Line chart
    --color-line-chart-current: var(--color-silver-rust);
    --color-line-chart-simulated: var(--color-international-orange);

    // Donut chart
    --color-donut-chart-1: var(--color-mandys-pink);
    --color-donut-chart-2: var(--color-aqua-forrest);
    --color-donut-chart-3: var(--color-fringy-flower);
    --color-donut-chart-4: var(--color-eastern-blue);
    --color-donut-chart-5: var(--color-morning-glory);
    --color-donut-chart-6: var(--color-almond-forrest);
    --color-donut-chart-7: var(--color-puerto-rico);
    --color-donut-chart-8: var(--color-fire-bush);
    --color-donut-chart-9: var(--color-golden-sand);
    --color-donut-chart-10: var(--color-cinnabar);
    --color-donut-chart-11: var(--color-green-pea);
    --color-donut-chart-12: var(--color-lochmara);
}