@import '@styles/_breakpoint.scss';

.header {
    color: var(--color-white);
    padding: 4rem 0;

    @media print {
        color: var(--color-black)
    }
}

.header__logo {
    margin-bottom: 1rem;
    width: 16rem;

    @include mq("sm") {
        margin-right: 2rem;
        margin-bottom: 0;   
    }

    @media print {
        filter: brightness(0);
    }
}

.header__identity {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
    text-align: center;

    @include mq("sm") {
        flex-direction: row; 
        margin-bottom: 0;
    }
   
}

.header__heading {
    position: relative;

    @include mq("sm") {
        padding-left: 2rem;
    }

    &:before {
        content: "";
        display: none;
        position: absolute;
        left: 0;
        width: .1rem; 
        height: 5rem;
        background-color: rgba(#fff, .2);
        top: 50%;
        transform: translateY(-50%);

        @include mq("sm") {
            display: block;
        }
    }
}

.header__button-wrapper {
    display: flex;
    justify-content: center;

    @include mq('sm') {
        justify-content: flex-end;
    }
}