.toggle {
    position: relative;
    padding: 4rem 0;
}

.toggle__divider {
    background-color: var(--color-offwhite);
    border: 0;
    height: .1rem;
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
}

.toggle__button {
    position: relative;
    z-index: 1;
    margin: 0 auto;
}

.toggle__icon {
    margin-right: .5rem;
}