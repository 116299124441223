@import '@styles/_breakpoint.scss';

.return-card {
    position: relative;
}

.return-card__heading {
    margin-bottom: 4rem;
}

.return-card__toggle {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%)
}

.return-card__header {
    position: relative;
}

.return-card__badge {
    margin: var(--spacing--sm) auto 0 auto;
    box-sizing: border-box;

    @include mq('sm') {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        margin: 0;
    }
}

.return-card__count {
    margin-bottom: var(--spacing--lg);
    text-align: center;

    @include mq('sm') {
        text-align: left;
    }

    &:nth-child(2) {
        @include mq('sm') {
            display: flex;
            justify-content: flex-end;
        }
    }

    &:last-child {
        margin-bottom: 0;
        
        .grid {
            justify-content: center;

            @include mq('sm') {
                justify-content: flex-start;
            }
        }
    }
}

.return-card__transfer {
    padding-bottom: var(--spacing--lg);
    margin-bottom: var(--spacing--lg);
    border-bottom: .1rem solid var(--color-offwhite);
}

.return-card__donut {
    margin-bottom: var(--spacing--lg);
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;

    @include mq('sm') {
        margin-bottom: 0;
    }
}