.line-chart {
    margin-left: -.8rem;
    margin-right: -.8rem;
}

.line-chart__tick {
    font-weight: 900;
    line-height: 125%;
    font-size: 1.2rem;
    letter-spacing: .1rem;
    color: var(--color-dove-gray);
}

.line-chart__tooltip {
    background-color: var(--color-white);
    padding: var(--spacing--sm);
    color: var(--color-dove-gray);
    box-shadow: 0 0 .1rem rgba(black, .2);
    line-height: 135%;
    font-size: 1.6rem;
}