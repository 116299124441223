.label {
    font-size: 1.6rem;
    line-height: 135%;
    color: var(--color-white);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .1rem;

    @media print {
        color: var(--color-black);
    }
}