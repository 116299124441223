.icon {
    display: block;
}

.icon__asset {
    width: 100%;
    height: 100%;
    fill: currentColor;
    color: currentColor;

    .icon--auto-width & {
        width: auto;
    }
}