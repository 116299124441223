.print-avoid-break {
	@media print {
		position: relative;
		break-inside: avoid;
		page-break-inside: avoid;
	}
}

.print-break-before {
	@media print {
		position: relative;
		break-before: always;
		page-break-before: always;
	}
}

.print-hidden {
	@media print {
		display: none!important;
	}
}

.grid__cell--print {
	@media print {
		flex: 1;
	}
}