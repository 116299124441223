$breakpoints: (
	'sm':     768px,
	'md':     960px
);

@mixin mq($width, $type: min) {
	@if map_has_key($breakpoints, $width) {
		$width: map_get($breakpoints, $width);
		@if $type == max {
			$width: $width - 1px;
		}
		@media (#{$type}-width: $width) {
			@content;
		}
	}
}