@import '@styles/_breakpoint.scss';

.form {
    background-color: var(--color-bottle-green);
    padding: var(--spacing--xl) var(--spacing--md) var(--spacing--2xl) var(--spacing--md);
    color: var(--color-white);
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;

    @media print {
        background-color: var(--color-white);
        color: var(--color-black);
    }

    @include mq('sm') {
        padding: var(--spacing--xl) var(--spacing--xl) var(--spacing--2xl) var(--spacing--xl);
    }
}